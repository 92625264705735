export const getEnglishAlphabet = () => {
  const result = []

  for (let i = 65; i < 91; i++) {
    result.push(String.fromCharCode(i))
  }

  return result
}

export const getGermanAlphabet = () => {
  let result = getEnglishAlphabet()

  result.push('Ä')
  result.push('Ö')
  result.push('Ü')
  
  return result
}

export const getSortedGlossary = glossary => {
  const result = {}
  const alphabet = getGermanAlphabet()

  for (let letter of alphabet) {
    result[letter] = glossary
      .filter(entry => entry.title[0] === letter)
      .sort((a, b) => {
        if (a.title < b.title) return -1
        if (a.title > b.title) return 1
        return 0
      })
  }

  return result
}

export const getFirstActiveLetter = glossary => {
  for (let key in glossary) {
    if (glossary[key].length !== 0) return key
  }

  return 'A'
}
