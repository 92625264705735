import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby';

import ContentHeaderBgImg from '../components/ContentHeaderBgImg'
import SEO from '../components/seo'

import {
  getGermanAlphabet,
  getSortedGlossary
} from '../utils/glossaryUtils'
import Layout from '../components/layout'

const Glossar = ({ data }) => {
  const { header, glossary } = data.gatsby
  const sortedGlossary = getSortedGlossary(glossary)
  const thumbnails = {
    thumbnailSmall: (header.thumbnailSmall.length ? header.thumbnailSmall[0].url : null),
    thumbnailMedium: (header.thumbnailMedium.length ? header.thumbnailMedium[0].url : null),
    thumbnailLarge: (header.thumbnailLarge.length ? header.thumbnailLarge[0].url : null)
  }


  return (
    <Layout>
      <SEO seomatic={header.seomatic} />
      {/* <Helmet>
            <meta name="robots" content="index, follow, max-image-preview:standard, max-snippet:-1, max-video-preview:-1" />
      </Helmet> */}
      <ContentHeaderBgImg
        title={header.headline}
        text={header.description}
        meta={header.excerpt}
        thumbnails={thumbnails}
      />
      <section>
        <div className="text-2xl mb-10 px-5 my-10 md:px-10 md:text-3.5xl">
          <div className="mx-auto max-w-screen-xxl">
            <div className="grid md:grid-cols-2 sm:gap-4 sm:row-gap-8 xl:grid-cols-3 xl:row-gap-16 mb-2 md:mb-5 xl:mb-8">
            {getGermanAlphabet().map((letter) => (
              <div className="">
                {sortedGlossary[letter].length !== 0 &&
                  <h2 className="text-2xl mb-4" key={letter}>
                    {letter}
                  </h2>
                }
                {sortedGlossary[letter].map(entry => (
                  <Link
                    to={`/${entry.uri.replace('glossary', 'glossar')}`}
                    key={entry.id} 
                    className="mb-8 md:mb-12 lg:mb-16"
                  >
                    <h3 className="text-17 mb-4 md:text-20">{entry.title}</h3>
                  </Link>
                ))}
              </div>
            ))}
            </div>
          </div>
        </div>

        
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    gatsby {
      header: entry(slug: "glossar") {
        ... on Gatsby_glossar_glossar_Entry {
          headline
          description
          excerpt
          seomatic {
            metaTitleContainer
            metaTagContainer
          }
          thumbnailLarge {
            url
          }
          thumbnailMedium {
            url
          }
          thumbnailSmall {
            url
          }
        }
      }
    }
    gatsby {
      glossary: entries(section: "glossary") {
        ... on Gatsby_glossary_glossary_Entry {
          id
          uri
          title
          text
        }
      }
    }
  }
`

export default Glossar
